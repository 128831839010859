.info {
    &__content {
        background: rgba(229, 229, 229, 0.46);
        border-radius: 1.4rem;
        padding: 2.5rem 1rem 2rem 1rem; }
    &__text {
        max-width: 725px;
        margin: 0 auto;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: center;
        color: #00477F; }
    &__btn-contact {
        margin: 2.14rem auto 0;
        max-width: 200px;
        padding: 0.6rem 2.85rem;
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: center;
        color: #00477F;
        background-color: #AFCFE94D; } }

@media screen and ( max-width: 500px ) {
    .info {
        &__text {
            font-size: 1.2rem;
            line-height: 1.5rem; } } }
