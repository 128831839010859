.contacts {
    margin: 2.3rem 0;
    &__content {
        display: flex;
        justify-content: space-between;
        padding: 3.57rem 6rem; }
    &__text {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        align-items: end;
        &>span {
            text-align: right;
            font-size: 20px;
            line-height: 24px;
            color: #00477F; }
        & a {
           color: #00477F; } }
    &__title {
        margin-top: 45px;
        font-weight: 500; }
    &__address {
        margin-top: 18px; }
    &__btn {
        margin-top: 52px;
        background: #AFCFE94D;
        color: #00477F;
        font-size: calc(16rem / 14);
        padding: calc(8rem / 14) calc(53rem / 14); }
    &__map {
        flex-basis: 45%;
        max-width: 34.3rem;
        height: 20.7857rem;
        border: 3px solid #00477f;
        border-radius: 20px;
        overflow: hidden; } }

@media screen and ( max-width: 1000px ) {
    .contacts {
        &__content {
            flex-direction: column;
            align-items: center;
            justify-content: start; }
        &__text {
            flex-direction: column;
            align-items: center;
            justify-content: start;
            &>span {
                text-align: center; } }
        &__title {
            margin-top: 2rem; }
        &__address {
            margin-bottom: 1rem; }
        &__btn {
            margin-top: 2rem; }

        &__map {
            margin-top: 2rem;
            width: 100%;
            min-height: 20.7857rem;
            max-width: 1000px; } } }

@media screen and ( max-width: 800px ) {
    .contacts {
        &__content {
            padding: 2rem 3rem; } } }

@media screen and ( max-width: 550px ) {
    .contacts {
        &__content {
            padding: 1rem 0; }
        &__title {
            margin-top: 0; }
        &__text {
            &>span {
                font-size: 1.2rem;
                line-height: 1.5rem; } } } }
