.product {
    margin: 30px 80px 70px;
    padding: 25px 40px 40px;
    background: rgba(229, 229, 229, 0.46);
    border-radius: 20px;
    min-height: 700px;
    &--load {
        position: relative;
        opacity: 0.5;
        &::after {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            width: 100%;
            box-shadow: 0 0 15px 2px #949494;
            animation: product_load 2s linear 0s infinite alternate; } }
    &__title {
        padding: 8px 0;
        color: #00477F;
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        background: rgba(175, 207, 233, 0.3);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 40px; }
    &__swiper {
        margin: 25px 0; }
    &__discription {
        margin-top: 25px;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #00477F; }
    &__characteristics {
        padding: 25px 0; }
    &__characteristic {
        margin-bottom: 14px;
        padding: 15px 23px;
        background: #E5E5E5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: rgba(0, 71, 127, 0.6); }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &__button {
        background: #AFCFE94D;
        padding: 8px 40px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #00477F; } }

@keyframes product_load {
    0% {
        top: 0; }
    100% {
        top: 700px; } }

.zvui-gallery-swiper-slides {
    margin: auto; }
.zvui-gallery-swiper-swipe {
    height: 450px;
    display: flex;
    align-items: center; }

.zvui-gallery-swiper-navigation.right {
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.175 477.175' style='enable-background:new 0 0 477.175 477.175;' xml:space='preserve'> <g> <path d='M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5 c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z ' fill='%2300477f'/> </g> </svg>") !important; }

.zvui-gallery-swiper-navigation.left {
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.175 477.175' style='enable-background:new 0 0 477.175 477.175;' xml:space='preserve'> <g> <path d='M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225 c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z' fill='%2300477f'/> </g></svg>") !important; }

.zvui-gallery-swiper-bullets-container li span {
    background-color: #00477F4d !important;
    box-shadow: inset 0 0 0 1px #00477F !important; }

.zvui-gallery-swiper-bullets-container li.active {
    background-color: #00477F !important; }


@media screen and ( max-width: 940px ) {
    .product {
        margin: 30px 0px 70px;
        padding: 25px 40px 40px; } }

@media screen and ( max-width: 650px ) {
    .product {
        margin: 15px 0px 35px;
        padding: 10px 20px 20px; } }

@media screen and ( max-width: 560px ) {
    .product {
        &__buttons {
            flex-direction: column-reverse; }
        &__button {
            margin: 1rem; } } }
