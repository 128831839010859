.product-gallery {
    margin-top: 2rem;
    max-width: 40%; }

.zvui-gallery-swiper-swipe {
    height: 60vh;
    display: flex;
    align-items: center;
    overflow: hidden; }

