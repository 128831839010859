.form {
    min-width: 50vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
    padding: 20px;
    padding-bottom: 25px;
    background: #F3F3F3;
    border: 6px solid rgba(175, 207, 233, 0.26);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    &__close {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        &>span {
            display: block;
            &::before {
                content: '';
                top: 0;
                position: absolute;
                width: 4px;
                height: 40px;
                background-color: #c4c4c4;
                display: block;
                transform: rotate(45deg);
                border-radius: 3px; }
            &::after {
                content: '';
                top: 0;
                position: absolute;
                width: 4px;
                height: 40px;
                background-color: #c4c4c4;
                display: block;
                transform: rotate(-45deg);
                border-radius: 3px; } } }
    &__title {
        margin: 0 0 20px 0;
        padding: 8px 40px;
        background: rgba(175, 207, 233, 0.3);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: #00477F; }
    &__content {
        width: 100%;
        background: rgba(255, 255, 255, 0.4);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        padding: 20px 10% 40px 10%; }
    &__text {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #00477F; }
    &__inputs-group {
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &__input-name, .form__input-phone {
        flex-basis: 45%; }
    &__input-phone>input {
        background: transparent;
        border: none;
        &::placeholder {
            font-size: 16px;
            line-height: 19px;
            color: #00477F99; } }
    &__input {
        width: 100%;
        padding: 10px 15px;
        border: none;
        background: #E5E5E5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        color: #00477F;
        &::placeholder {
            font-size: 16px;
            line-height: 19px;
            color: #00477F99; } }
    &__input-email {
        margin-top: 30px;
        margin-bottom: 23px; }
    &__input-model {
        margin-bottom: 30px; }
    &__input-massage {
        min-width: 100%;
        max-width: 100%;
        min-height: 80px;
        max-height: 80px;
        margin-bottom: 30px; }
    &__btn-submit {
        display: block;
        margin: 0 auto;
        padding: 8px 70px;
        background: rgba(175, 207, 233, 0.3);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        color: #00477F;
        cursor: pointer; } }


@media screen and ( max-width: 650px ) {
    .form {
        &__text {
            font-size: 14px;
            margin-bottom: 15px; }
        &__inputs-group {
            flex-direction: column;
            justify-content: start; }
        &__input-name {
            flex-basis: 100%; }
        &__input-phone {
            flex-basis: 100%;
            margin-top: 2rem; }
        &__content {
            padding: 20px 2rem 40px 2rem; } } }
