*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
  text-decoration: none; }

html {
  font-size: 14px; }

body {
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  overflow-x: hidden; }

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; }

img {
  max-width: 100%; }

// /*Убрать полосу прокрутки для всей страницы*/
// ::-webkit-scrollbar
//   width: 0

.container {
  position: relative;
  max-width: 1200px;
  padding: 0 2rem;
  margin: 0 auto; }

.button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: none;
  cursor: pointer; }


.lds-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #3f51b5;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3f51b5 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }



@media screen and ( max-width: 1200px ) {
  html {
    font-size: 12px; } }



