.section-title {
  display: inline-block;
  background: rgba(231, 241, 248, 0.2);
  color: #FFFFFF;
  font-size: calc(20rem / 14);
  padding: calc(8rem / 14) calc(90rem / 14);
  margin: 1rem auto;
  border-radius: 30px;
  font-weight: normal;
  text-align: center;
  &-block {
    display: flex;
    justify-content: center;
    background: #00477F;
    border-radius: 6px; } }


@media screen and ( max-width: 600px ) {
  .section-title {
    padding: calc(8rem / 14) calc(40rem / 14); } }
