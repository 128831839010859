.main-page {
    &-catalog {
        &__cards {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 24px 63px 48px; }
        &__card {
            background: #e5e5e575;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            flex-basis: 30%;
            margin-bottom: 25px;
            min-height: 370px;
            &>div {
                padding: calc(20rem / 14);
                &>img {
                    border-radius: 20px; } }
            &>span {
                display: block;
                margin-bottom: calc(54rem / 14);
                padding: 0 calc(20rem / 14);
                color: #00477F;
                font-size: calc(20rem / 14);
                line-height: calc(24rem / 14);
                text-align: center; }
            &--load {
                position: relative;
                opacity: 0.5;
                &::after {
                    position: absolute;
                    top: 0;
                    content: '';
                    width: 100%;
                    box-shadow: 0 0 15px 2px #949494;
                    animation: card_load 1s linear 0s infinite alternate; } } }

        &__bottom-btn {
            display: block;
            max-width: 306px;
            margin: 0 auto calc(31rem / 14);
            padding: 9px 0;
            font-size: 20px;
            line-height: 24px;
            font-weight: normal;
            text-align: center;
            color: #ffffff;
            background: #00477F; } }
    &-info {
        &__content {
            background: rgba(229, 229, 229, 0.46);
            border: 10px solid rgba(175, 207, 233, 0.48);
            border-radius: 1.42rem;
            padding: 7.14rem 0;
            margin: 2.5rem 5.7rem 2.14rem 5.7rem; }
        &__text {
            max-width: 725px;
            margin: 0 auto;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.7rem;
            text-align: center;
            color: #00477F; }
        &__btn-contact {
            margin: 0 5.7rem 0 auto;
            max-width: 200px;
            padding: 0.6rem 2.85rem;
            font-size: 1.4rem;
            line-height: 1.7rem;
            text-align: center;
            color: #00477F;
            background-color: #AFCFE94D; } } }

.slider {
    margin-bottom: 3.85rem; }

@keyframes card_load {
    0% {
        top: 0; }
    100% {
        top: 370px; } }


@media screen and ( max-width: 940px ) {
    .main-page-info__content {
        padding: 4rem 1rem;
        margin: 2.5rem 3rem 2.14rem 3rem; } }
@media screen and ( max-width: 800px ) {
    .slider {
        margin-bottom: 2rem; }
    .main-page {
        &-catalog {
            &__card {
                flex-basis: 45%;
                &>span {
                    margin-bottom: calc(20rem / 14); } } } } }

@media screen and ( max-width: 700px ) {
    .main-page {
        &-catalog {
            &__cards {
                margin: 24px 30px 24px; } }
        &-info {
            &__content {
                padding: 2rem 1rem;
                margin: 2.5rem 0rem 2.14rem 0rem; }
            &__text {
                font-size: 1rem;
                line-height: 1.4rem; }
            &__btn-contact {
                margin: 0 auto; } } } }

@media screen and ( max-width: 540px ) {
    .swiper-button-prev, .swiper-button-next {
        display: none; }
    .slider {
        margin-bottom: 1.5rem; }
    .main-page {
        &-catalog {
            &__cards {
                margin-bottom: 0; }
            &__card {
                flex-basis: 100%;
                &:nth-of-type(4) {
                    display: none; }
                &:nth-of-type(5) {
                    display: none; }
                &:nth-of-type(6) {
                    display: none; } } } } }

@media screen and ( max-width: 400px ) {
    .slider {
        margin-bottom: 1rem; } }
