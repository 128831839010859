.product-card {
    display: flex;
    align-items: center;
    position: relative;
    &__img {
        height: 7rem;
        margin-right: 1rem;
        border-radius: 10px; }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-height: 100%; }
    &__visibility {
        position: absolute;
        top: 10px;
        right: 10px; }
    &__title {
        margin: 0; }
    &__discription {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis; }
    &__price {
        text-align: right;
        margin-top: 1rem; } }

.btn-group {
    display: flex;
    align-items: center; }
