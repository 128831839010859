.header {
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem calc( 67rem / 14 ); }
    &__buttons {
        display: flex;
        flex-direction: column;
        &>.button {
            background: #AFCFE94D;
            color: #00477F;
            font-size: calc(16rem / 14);
            padding: calc(8rem / 14) calc(53rem / 14); } }
    &__contact-btn {
        margin-bottom: 0.8rem; } }

.nav {
    display: flex;
    justify-content: space-between;
    padding: calc(12rem / 14) calc(66rem / 14);
    background: #00477F;
    border-radius: 6px;
    &__link {
        background: #6D81924D;
        color: #FFFFFF;
        font-size: calc(16rem / 14);
        padding: calc(5rem / 14) calc(45rem / 14); } }

.burger-menu-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;

    display: none; }

.burger-menu {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #00477F;
    &::before {
        content: '';
        position: absolute;
        top: 10px;
        display: block;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background: #00477F; }
    &::after {
        content: '';
        position: absolute;
        bottom: 10px;
        display: block;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background: #00477F; } }

.side-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00477F;
    transform-origin: right;
    z-index: 100;
    transform: scale(0, 1);
    transition: transform 0.2s;
    &--active {
        transform: scale(1, 1); }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25vh;
        width: 25vh;
        margin: 5vh auto 0;
        padding: 20px;
        background: #fff;
        border-radius: 50%; }
    &__nav {
        height: 60vh;
        padding: 5vh 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
    &__link {
        background: #2E6997;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        padding: 1rem 0; }
    &__close {
        position: absolute;
        top: 0px;
        right: 10px;
        cursor: pointer;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        &>span {
            display: block;
            &::before {
                content: '';
                top: 0;
                position: absolute;
                width: 4px;
                height: 40px;
                background-color: #c4c4c4;
                display: block;
                transform: rotate(45deg);
                border-radius: 3px; }
            &::after {
                content: '';
                top: 0;
                position: absolute;
                width: 4px;
                height: 40px;
                background-color: #c4c4c4;
                display: block;
                transform: rotate(-45deg);
                border-radius: 3px; } } } }



@media screen and ( max-width: 780px ) {
    .header {
        &__top {
            padding: 1rem 2rem; } }
    .nav {
        padding: calc(12rem / 14) 2rem; } }

@media screen and ( max-width: 670px ) {
    .nav {
        display: none; }
    .burger-menu-box {
        display: flex; } }

@media screen and ( max-width: 580px ) {
    .header__buttons {
        display: none; } }
