.footer {
    &__top-line {
        height: 19px;
        background: #00477F;
        border-radius: 6px; }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding: 25px 19px 19px 19px; }
    &__left, .footer__right {
        &>span {
            display: block;
            font-size: 14px;
            line-height: 17px;
            color: #00477F;
            &>a {
                color: #00477F; } } }
    &__right>span {
        max-width: 280px;
        text-align: right; } }

@media screen and ( max-width: 590px ) {
    .footer {
        &__content {
            flex-direction: column;
            justify-content: start;
            align-items: center; }
        &__left, .footer__right {
            &>span {
                text-align: center; } }
        &__left {
            margin-bottom: 1rem; }
        &__title {
            margin-bottom: 1rem; } } }
