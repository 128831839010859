.product-gallery {
    margin-top: 2rem;
    width: 48%; }

.zvui-gallery-swiper-swipe {
    height: 60vh;
    display: flex;
    align-items: center; }

.no-images {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.main-content {
    max-height: 60vh;
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-top: 5rem; }
